import { fadeText, Animate } from '../../lib/home/utils';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import useCharactor from '../../lib/hooks/use-charactor';
import { EffectCoverflow } from 'swiper';
import useRTEventCharactor from '../../lib/hooks/use-rt-event-charactor';
import { useEffect } from 'react';
import { useState } from 'react';
import LoadingIcon from '../temp/loadingIcon';

const Wave = ({ src, percent, targetHeadPercent, ...props }) => {
  const [mHeight, setHeight] = useState(0);
  useEffect(() => {
    setHeight(((38 - percent) / 100) * 1900);
  }, [percent]);

  const waters = [
    {
      opacity: 0.2,
      translate: ['-270 0', '-374 20', '-662 0', '-700 -20', '-845 0'],
      dur: '2s',
    },
    {
      opacity: 0.2,
      translate: ['-130 10', '-255 25', '-480 0', '-617 20', '-705 10'],
      dur: '2.3s',
    },
    {
      opacity: 1,
      translate: ['0 0', '0 -5', '0 -10', '0 7', '0 0'],
      dur: '2.9s',
    },
  ];

  const waterPatterns = [{ x: -12.5 }];

  if (percent >= 100) {
    return <></>;
  }

  // if (percent > 90) {
  //   const targetHeight = (1600 / 100) * targetHeadPercent;
  //   return (
  //     <svg
  //       version="1.1"
  //       xmlns="http://www.w3.org/2000/svg"
  //       x="0px"
  //       y="0px"
  //       width="100%"
  //       height="100%"
  //       viewBox="0 0 574 1800"
  //       enableBackground="new 0 0 574 1800"
  //       {...props}
  //     >
  //       <defs>
  //         <pattern
  //           id="water"
  //           width="1"
  //           height="100%"
  //           patternContentUnits="objectBoundingBox"
  //           viewBox="0 0 1148 580"
  //           enableBackground="new 0 0 1148 580"
  //         >
  //           {waters.map(({ opacity, translate, dur }, index) => (
  //             <path
  //               key={index}
  //               fill="#fff"
  //               fillOpacity={opacity}
  //               d="M2296.7,0v464c0,0-114,16.9-291.3,0c-178.3-16.9-282.7,0-282.7,0s-113,16.9-291.3,0c-178.3-16.9-282.7,0-282.7,0
  //             s-113,16.9-291.3,0c-178.3-16.9-282.7,0-282.7,0s-113,16.9-291.3,0c-178.3-16.9-282.7,0-284,0V0H2296.7z"
  //             >
  //               <animateTransform
  //                 attributeName="transform"
  //                 attributeType="XML"
  //                 type="translate"
  //                 values={translate.join(';')}
  //                 dur={dur}
  //                 repeatCount="indefinite"
  //               />
  //             </path>
  //           ))}
  //         </pattern>
  //         <pattern
  //           id="top-water"
  //           width="1"
  //           height="100%"
  //           patternContentUnits="objectBoundingBox"
  //           viewBox="0 0 1148 580"
  //           enableBackground="new 0 0 1148 580"
  //         >
  //           {waters.map(({ opacity, translate, dur }, index) => (
  //             <path
  //               key={index}
  //               fill="#000"
  //               fillOpacity={opacity}
  //               d="M2296.7,0v464c0,0-114,16.9-291.3,0c-178.3-16.9-282.7,0-282.7,0s-113,16.9-291.3,0c-178.3-16.9-282.7,0-282.7,0
  //             s-113,16.9-291.3,0c-178.3-16.9-282.7,0-282.7,0s-113,16.9-291.3,0c-178.3-16.9-282.7,0-284,0V0H2296.7z"
  //             >
  //               <animateTransform
  //                 attributeName="transform"
  //                 attributeType="XML"
  //                 type="translate"
  //                 values={translate.join(';')}
  //                 dur={dur}
  //                 repeatCount="indefinite"
  //               />
  //             </path>
  //           ))}
  //         </pattern>
  //         <mask id={`water-mask-${percent}-${targetHeadPercent}`}>
  //           {waterPatterns.map(({ x }, index) => (
  //             <rect
  //               x="0"
  //               y={`${mHeight / 2 - 270 + targetHeight}`}
  //               width="200%"
  //               height="100%"
  //               fill="url(#water)"
  //               key={index}
  //               transform="scale(1.5, 1)"
  //             >
  //               <animate
  //                 attributeName="x"
  //                 from={`${x}%`}
  //                 to={`${x - 100}%`}
  //                 begin="0s"
  //                 dur="5s"
  //                 repeatCount="indefinite"
  //               />
  //             </rect>
  //           ))}
  //           <rect
  //             x="-25%"
  //             y="0"
  //             width="200%"
  //             height={`${mHeight / 2 + 680 + targetHeight >= 0 ? mHeight / 2 + 680 + targetHeight : 0}px`}
  //             fill="#fff"
  //           ></rect>
  //           {waterPatterns.map(({ x }, index) => (
  //             <rect
  //               x="0"
  //               y={`${-mHeight / 2 - 1500 + targetHeight}`}
  //               width="200%"
  //               height="100%"
  //               fill="url(#top-water)"
  //               key={index}
  //               transform="scale(1.5, 1)"
  //             >
  //               <animate
  //                 attributeName="x"
  //                 from={`${x}%`}
  //                 to={`${x - 100}%`}
  //                 begin="0s"
  //                 dur="5s"
  //                 repeatCount="indefinite"
  //               />
  //             </rect>
  //           ))}
  //           <rect
  //             x="-25%"
  //             y="0"
  //             width="200%"
  //             height={`${-mHeight / 2 - 800 + targetHeight >= 0 ? -mHeight / 2 - 800 + targetHeight : 0}px`}
  //             fill="#000"
  //           ></rect>
  //         </mask>
  //       </defs>
  //       <image
  //         mask={`url(#water-mask-${percent}-${targetHeadPercent})`}
  //         xlinkHref={src}
  //         width="130%"
  //         height="100%"
  //         x="-15%"
  //       />
  //     </svg>
  //   );
  // }


  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="100%"
      height="100%"
      viewBox="0 0 574 1800"
      enableBackground="new 0 0 574 1800"
      {...props}
    >
      <defs>
        <pattern
          id="water"
          width="1"
          height="100%"
          patternContentUnits="objectBoundingBox"
          viewBox="0 0 1148 580"
          enableBackground="new 0 0 1148 580"
        >
          {waters.map(({ opacity, translate, dur }, index) => (
            <path
              fill="#fff"
              fillOpacity={opacity}
              d="M2296.7,0v464c0,0-114,16.9-291.3,0c-178.3-16.9-282.7,0-282.7,0s-113,16.9-291.3,0c-178.3-16.9-282.7,0-282.7,0
          s-113,16.9-291.3,0c-178.3-16.9-282.7,0-282.7,0s-113,16.9-291.3,0c-178.3-16.9-282.7,0-284,0V0H2296.7z"
              key={index}
            >
              <animateTransform
                attributeName="transform"
                attributeType="XML"
                type="translate"
                values={translate.join(';')}
                dur={dur}
                repeatCount="indefinite"
              />
            </path>
          ))}
        </pattern>
        <mask id={`water-mask-${percent}`}>
          <rect x="-25%" y="0" width="200%" height={`${875 + mHeight * 1.1}px`} fill="#fff"></rect>
          {waterPatterns.map(({ x }, index) => (
            <rect
              x="0"
              y={`${mHeight * 1.1}`}
              width="200%"
              height="100%"
              fill="url(#water)"
              key={index}
              transform="scale(1.5, 1)"
            >
              <animate
                attributeName="x"
                from={`${x}%`}
                to={`${x - 100}%`}
                begin="0s"
                dur="5s"
                repeatCount="indefinite"
              />
            </rect>
          ))}
        </mask>
      </defs>
      <image mask={`url(#water-mask-${percent})`} xlinkHref={src} width="150%" height="100%" x="-25%" />
    </svg>
  );
};

const RtImage = ({ src, lastSrc, alt, countRetweet, maxRetweet, targetHeadPercent }) => {
  const percent = Math.floor((countRetweet / maxRetweet) * 10000) / 100;
  return (
    <figure className="relative h-full w-full rounded-lg overflow-hidden">
      <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-full h-full z-10">
        <Wave className="w-full h-full" src={lastSrc} percent={percent} targetHeadPercent={targetHeadPercent} />
      </div>
      <img src={src} alt={alt} className="h-full absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" />
    </figure>
  );
};

const Image = ({ src, alt }) => {
  return (
    <figure className="relative h-full w-full rounded-lg overflow-hidden">
      <img src={src} alt={alt} className="h-full absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" />
    </figure>
  );
};

const Box = ({ children, height = '100%' }) => (
  <div
    className="relative aspect-[8/16]"
    style={{
      height,
      //* 이미지 크기는 여기 높이로 조절하세요 */
    }}
  >
    {children}
  </div>
);

const TwitchIcon = () => (
  <svg
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    version="1.1"
    viewBox="0 0 16 16"
    height="1em"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1.5 0l-1.5 2.5v11.5h4v2h2l2-2h2.5l4.5-4.5v-9.5h-13.5zM13 8.5l-2.5 2.5h-2.5l-2 2v-2h-3v-9h10v6.5z"></path>
    <path d="M9.5 4h1.5v4h-1.5v-4z"></path>
    <path d="M6.5 4h1.5v4h-1.5v-4z"></path>
  </svg>
);

const ChzzkIcon = () => {
  return (
    <figure className="relative h-[1.2em] w-[1.2em]">
      <img src="/chzzk.png" alt="치지직 아이콘" className="h-full absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" />
    </figure>
  );
};

const XIcon = () => {
  return (
    <figure className="relative h-[1.2em] w-[1.2em]">
      <img src="/x.png" alt="치지직 아이콘" className="h-full absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" />
    </figure>
  );
};


const Chzzk = ({name = '', colors, href }) => (
  <div
    className="absolute left-1/2 bottom-10 -translate-x-1/2 transition-transform z-20 flex flex-col items-center gap-4 w-full px-2"
    style={{
      fontFamily: '',
    }}
  >
    <a
      href={href}
      target="_blank"
      className="btn btn-xs text-white inline-flex justify-center gap-2 rounded-full border-0"
      style={{
        backgroundColor: colors.name,
        color: '#ffffff',
      }}
      rel="noreferrer"
    >
      <span className="text-xs">chzzk</span>
      <span className="text-sm">
        <ChzzkIcon src="/chzzk.png" width={20} height={20} />
      </span>
    </a>
    <div
      className="font-charactor flex flex-col items-center gap-2 w-[40%]"
      style={{
        textShadow: `0 1px 2px ${colors.shadow}, 0 0 10px rgba(255, 255, 255, 0.7)`,
      }}
    >
      <p className="flex flex-col text-white h-10 items-center">
        {name.split(' ').map((value, index) => (
          <span key={index} style={{ textShadow: `0 1px 5px #000` }}>
            {value}
          </span>
        ))}
      </p>
      <hr className="w-full" style={{ color: colors.name, boxShadow: '0 0 5px #ffffff' }} />
      <span
        className="text-[0.7rem]"
        style={{
          color: '#ffffff',
          textShadow: `0 1px 3px #000`,
        }}
      >
        LISTELLA
      </span>
    </div>
  </div>
);

const Retweet = ({name = '', colors, href }) => (
  <div
    className="absolute left-1/2 bottom-10 -translate-x-1/2 transition-transform z-20 flex flex-col items-center gap-4 w-full px-2"
    style={{
      fontFamily: '',
    }}
  >
    <a
      href={href}
      target="_blank"
      className="btn btn-xs text-white inline-flex justify-center gap-2 rounded-full border-0"
      style={{
        backgroundColor: colors.name,
        color: '#ffffff',
      }}
      rel="noreferrer"
    >
      <span className="text-xs">X.com</span>
      <span className="text-sm">
        <XIcon src="/chzzk.png" width={20} height={20} />
      </span>
    </a>
    <div
      className="font-charactor flex flex-col items-center gap-2 w-[40%]"
      style={{
        textShadow: `0 1px 2px ${colors.shadow}, 0 0 10px rgba(255, 255, 255, 0.7)`,
      }}
    >
      <p className="flex flex-col text-white h-10 items-center">
        {name.split(' ').map((value, index) => (
          <span key={index} style={{ textShadow: `0 1px 5px #000` }}>
            {value}
          </span>
        ))}
      </p>
      <hr className="w-full" style={{ color: colors.name, boxShadow: '0 0 5px #ffffff' }} />
      <span
        className="text-[0.7rem]"
        style={{
          color: '#ffffff',
          textShadow: `0 1px 3px #000`,
        }}
      >
        LISTELLA
      </span>
    </div>
  </div>
);

const CharacterSection = ({ w, h }) => {
  const { charactor } = useCharactor();
  // const { charactor:eventCharactor, loading } = useRTEventCharactor();

  return (
    <div className="hero-overlay bg-opacity-50 absolute none z-20">
      <div className="section-content">
        <Animate variants={fadeText} delay={300}>
          <h3 className={`${w < h ? 'my-[6vh]' : 'my-[8vh]'}`}>스텔라</h3>
        </Animate>
        <Animate variants={fadeText} delay={800} className="grow shrink box-border">
          <article className="h-full pb-[10vh]">
            <Swiper
              height="100%"
              grabCursor={true}
              centeredSlides={true}
              loop={true}
              slidesPerView={3}
              slideToClickedSlide={true}
              initialSlide={0}
              autoplay
              allowTouchMove={true}
              effect="coverflow"
              navigation={true}
              // spaceBetween={-300}
              pagination={{
                clickable: true,
             }}
              coverflowEffect={{
                rotate: 30,
                stretch: 0,
                depth: -50,
                modifier: 1,
                slideShadows: false,
              }}
              modules={[EffectCoverflow, Navigation]}
              className="h-full w-screen webL:max-w-[80vw]"
              breakpoints={{
                320: {
                  slidesPerView: 1,
                  grabCursor: true,
                  slideToClickedSlide: true,
                  allowTouchMove: true,
                  loop: true,
                  coverflowEffect: {
                    rotate: 30,
                    stretch: 0,
                    depth: 0,
                    modifier: 1,
                    slideShadows: false,
                    scale: 1.05,
                  },
                },
                400: {
                  slidesPerView: 1,
                  grabCursor: true,
                  slideToClickedSlide: true,
                  allowTouchMove: true,
                  loop: true,
                  coverflowEffect: {
                    rotate: 0,
                    stretch: 0,
                    depth: 0,
                    modifier: 1,
                    slideShadows: false,
                    scale: 1,
                  },
                },
                500: {
                  slidesPerView: 1,
                  grabCursor: true,
                  slideToClickedSlide: true,
                  allowTouchMove: true,
                  loop: true,
                  coverflowEffect: {
                    rotate: 30,
                    stretch: 0,
                    depth: 0,
                    modifier: 1,
                    slideShadows: false,
                    scale: 1.05,
                  },
                },
                720: {
                  slidesPerView: 1,
                  grabCursor: true,
                  slideToClickedSlide: true,
                  allowTouchMove: true,
                  loop: true,
                  coverflowEffect: {
                    rotate: 30,
                    stretch: 0,
                    depth: 0,
                    modifier: 1,
                    slideShadows: false,
                    scale: 1.05,
                  },
                },
                960: {
                  slidesPerView: 3,
                  grabCursor: true,
                  slideToClickedSlide: true,
                  allowTouchMove: true,
                  loop: true,
                  coverflowEffect: {
                    rotate: 0,
                    stretch: 0,
                    depth: 0,
                    modifier: 1,
                    scale: 1,
                    slideShadows: false,
                  },
                },
              }}
            >
              {/* {Object.entries(eventCharactor).map(([name, data], index) => {
                const { retweetCount, imageURL, coverImage, colors, twitterLink, targetHeadPercent } = data;
                if (loading[name] && imageURL && coverImage) {
                  return (
                    <SwiperSlide className="flex items-start" key={name}>
                      <Box height="90%">
                        <RtImage
                          src={imageURL}
                          lastSrc={coverImage}
                          alt={`${index + 1}번째 스텔라 이미지입니다.`}
                          countRetweet={retweetCount - 100}
                          targetHeadPercent={targetHeadPercent}
                          maxRetweet={300}
                        />
                        <Retweet
                          countRetweet={retweetCount}
                          maxRetweet={400}
                          href={twitterLink}
                          name={name}
                          colors={colors}
                          index={index}
                        />
                      </Box>
                    </SwiperSlide>
                  );
                }
                return (
                  <SwiperSlide key={name}>
                    <LoadingIcon size={150} />
                  </SwiperSlide>
                );
              })} */}
              {Object.entries(charactor).map(([name, data], index) => {
                const { imageURL, colors, platformLink } = data;
                return (
                  <SwiperSlide
                    className="flex items-start mx-0"
                    key={name}
                  >
                    <Box height="90%">
                      <Image
                        src={imageURL}
                        alt={`${index + 1}번째 스텔라 이미지입니다.`}
                      />
                      <Chzzk href={platformLink} name={name} colors={colors} index={index} />
                    </Box>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </article>
        </Animate>
        <div className="absolute left-1/2 bottom-0 -translate-x-1/2 fadeIn fast">
          <img className="z-30 animate-bounce" src="./scroll-down.svg" alt="scroll-down" style={{ width: '8vh' }} />
        </div>
      </div>
    </div>
  );
};

export default CharacterSection;
