import { useEffect, useRef, useState } from 'react';
import { debounce } from 'loadsh';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import { ToastContainer, toast, Slide } from 'react-toastify';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import YouTube from 'react-youtube';

import { NewsSection } from '../../components/home/NewsSection';
import { fadeText, Animate, fadeBtn } from '../../lib/home/utils';

import { Section } from '../../components/home/Section';
import { MediaSection } from '../../components/home/MediaSection';
import CharacterSection from '../../components/home/CharactorSection';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';
import ScheduleSection from '../../components/home/schdule-section';

function Home() {
  const { hash } = useLocation();
  useEffect(() => {
    if (hash) {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [hash]);

  const [popup, setPopup] = useState(false);
  const [hbg, setHbg] = useState(false);
  const [click, setClick] = useState(false);
  const [nav, setNav] = useState(0);
  const shareRef = useRef(null);
  const youtubeRef = useRef(null);
  const [mWidth, setWidth] = useState(window.innerWidth);
  const [mHeight, setHeight] = useState(window.innerHeight);

  const notify = () => {
    toast('복사가 완료되었습니다.', {
      position: 'bottom-center',
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: 'dark',
      transition: Slide,
    });
    toast.clearWaitingQueue();
  };

  const handleToggleClick = (e) => setClick((prev) => !prev);

  const handleResize = debounce(() => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
    if (mWidth > mHeight * 100) {
      setHbg(false);
    }
    document.documentElement.style.setProperty('--h', `${mHeight / 100}px`);
  }, 300);

  useEffect(() => {
    if (popup) {
      youtubeRef.current.internalPlayer.playVideo();
    } else {
      youtubeRef.current.internalPlayer.stopVideo();
    }
  }, [popup]);

  useEffect(() => {
    const video = document.getElementById('bgVideo');

    video.addEventListener('loadeddata', () => {
      if (video.readyState === 4) {
        video.style.display = 'block';
      }
    });

    video.addEventListener('pause', () => {
      video.play();
    });
  });

  useEffect(() => {
    handleResize();

    const handleClickOutSide = (e) => {
      if (shareRef.current && !shareRef.current.contains(e.target)) {
        setClick(false);
      }
    };

    window.addEventListener('resize', handleResize);
    document.addEventListener('mousedown', handleClickOutSide);

    return () => {
      window.removeEventListener('resize', handleResize);
      document.removeEventListener('mousedown', handleClickOutSide);
    };
  }, [handleResize, shareRef]);

  return (
    <div className="home hero bg-white">
      <ToastContainer limit={1} />
      <div id="fullPage" className="fullPage bg-[#343636] snap-y snap-mandatory">
        {/* Layered Popup */}
        <div
          className={`hero-overlay h-screen bg-opacity-90 absolute z-50 ${popup ? 'block' : 'hidden'}`}
          onClick={() => setPopup(false)}
        >
          <div
            className="w-screen flex flex-col absolute left-1/2 -translate-x-1/2 -translate-y-1/2"
            style={{ top: '40%', maxWidth: '100vh' }}
          >
            <div>
              <div className="flex flex-row justify-center pb-4">
                <div className="flex items-end">
                  <img className="max-w-xs z-30" src="./logo+text.svg" alt="logo" style={{ mWidth: '30vh' }} />
                </div>
              </div>
            </div>
            <YouTube
              ref={youtubeRef}
              videoId="l_XzGEr6dMI"
              className="aspect-video"
              iframeClassName="w-full h-full"
              opts={{
                playerVars: {
                  rel: 0, //관련 동영상 표시
                  modestbranding: 1, // 컨트롤 바에 youtube 로고를 표시하지 않음
                },
              }}
            />
          </div>
        </div>
        {/* Navigation Bar */}
        <nav
          className={clsx(
            'stroke blurGlass w-screen fixed overflow-hidden flex flex-row justify-between items-start',
            'text-center top-0 z-40 text-base fadeIn fast transitionFastest',
            hbg === true ? 'h-[60vh]' : 'h-[8vh]',
          )}
        >
          {/* Navigation Left */}
          <div
            className={clsx(
              'justify-start items-center h-[8vh] pt-1 pl-[1vw]',
              mWidth < mHeight ? 'w-[50vw]' : 'w-[25vw]',
            )}
          >
            {/* Hamburger */}
            {mWidth <= mHeight && (
              <div className="hidden flex-col items-center relative w-[6vh] h-[8vh]">
                <button
                  className={`hamburger w-[3vh] h-full absolute ${
                    hbg === true ? 'opacity-0 -z-10 -rotate-45' : 'opacity-100 z-10 rotate-0'
                    }`}
                  onClick={() => setHbg(true)}
                />
                <button
                  className={`navUp w-[3vh] h-full absolute ${
                    hbg === true ? 'opacity-100 z-10 rotate-0' : 'opacity-0 -z-10 rotate-90'
                    }`}
                  onClick={() => setHbg(false)}
                />
              </div>
            )}

            {/* Logo */}
            <a name="navBtn" className="flex flex-col justify-center ml-[1vw] h-[8vh]" href="/home#0">
              <img src="./logo+text-landscape.svg" alt="logo" className="w-[20vh]" />
            </a>
          </div>

          {/* Navigation Center */}
          <ul
            className={clsx(
              'w-[50vw] h-[8vh] pt-1 justify-center text-white',
              mWidth <= mHeight &&
                `slideDown transitionFastest flex-col items-center mt-[8vh] ${
                  hbg ? ' opacity-100 translate-y-[20vh] z-20' : 'opacity-0 translate-y-0 -z-10'
              }`,
              'web:flex hidden',
            )}
          >
            {/* <li className={`navBtn ${mWidth < mHeight && 'm-[2vh]'}`}>
              <a
                name="navBtn"
                className={`navAncher font-medium flex justify-center items-center w-[12vh] h-[6vh] mx-6 ${
                  nav === '1' && 'active'
                  }`}
                href="/home#1"
              >
                스케줄
              </a>
            </li> */}
            <li className={`navBtn ${mWidth < mHeight && 'm-[2vh]'}`}>
              <a
                name="navBtn"
                className={`navAncher font-medium flex justify-center items-center w-[12vh] h-[6vh] mx-6 ${
                  nav === '2' && 'active'
                  }`}
                href="/home#2"
              >
                소식
              </a>
            </li>
            <li className={`navBtn ${mWidth < mHeight && 'm-[2vh]'}`}>
              <a
                name="navBtn"
                className={`navAncher font-medium flex justify-center items-center w-[12vh] h-[6vh] mx-6 ${
                  nav === '3' && 'active'
                  }`}
                href="/home#3"
              >
                세계관
              </a>
              {/* <ul className="font-semibold fadeIn fastest" style={{ fontSize: '2vh', paddingTop: '2vh' }}>
                <li><p>세계관</p></li>
              </ul> */}
            </li>
            <li className={`navBtn ${mWidth < mHeight && 'm-[2vh]'}`}>
              <a
                name="navBtn"
                className={`navAncher font-medium flex justify-center items-center w-[12vh] h-[6vh] mx-6 ${
                  nav === '4' && 'active'
                  }`}
                href="/home#4"
              >
                스텔라
              </a>
            </li>
            <li className={`navBtn ${mWidth < mHeight && 'm-[2vh]'}`}>
              <a
                name="navBtn"
                className={`navAncher font-medium flex justify-center items-center w-[12vh] h-[6vh] mx-6 ${
                  nav === '5' && 'active'
                  }`}
                href="/home#5"
              >
                미디어
              </a>
            </li>
          </ul>

          {/* Navigation Right */}
          <ul
            className={`flex flex-row pt-1 pr-[1vw] items-center justify-end h-[8vh] ${
              mWidth < mHeight ? 'w-[50vw]' : 'w-[25vw]'
              }`}
          >
            {/* Youtube Button */}
            <li className="flex flex-col items-center w-[6vh]">
              <a
                href="https://www.youtube.com/channel/UCGyXcENDHN_C3POdU-BiZXg?sub_confirmation=1"
                target="_blank"
                rel="noreferrer"
              >
                <div className="youtube mx-[1.5vh] w-[3vh] h-[8vh] p-0" />
              </a>
            </li>
            {/* Twitter Button */}
            <li className="flex flex-col items-center w-[6vh]">
              <a
                href="https://twitter.com/intent/follow?original_referer=https%3A%2F%2Flistella.tv%2F&ref_src=twsrc%5Etfw%7Ctwcamp%5Ebuttonembed%7Ctwterm%5Efollow%7Ctwgr%5Elistella_on&screen_name=listella_on"
                target="_blank"
                rel="noreferrer"
              >
                <div className="twitter mx-[1.5vh] w-[3vh] h-[6vh] p-0" />
              </a>
            </li>

            {/* Share Button */}
            <li className="flex flex-col items-center w-[6vh]">
              <button className="share flex w-[3vh] h-[8vh] p-0" onClick={() => handleToggleClick()}></button>
            </li>
          </ul>
        </nav>

        {/* Share Box */}
        <div
          ref={shareRef}
          className={`share-box z-40 absolute flex flex-col justify-center right-0 mt-[9vh] w-[8vh]  ${
            click ? 'scale-100 h-[28vh]' : 'scale-0 h-[56vh]'
            }`}
        >
          <ul className="flex justify-center items-center m-1 division">
            <FacebookShareButton url={'https://listella.tv/'}>
              <div className="facebook w-[3vh] h-[8vh] p-0" />
            </FacebookShareButton>
          </ul>
          <ul className="flex justify-center items-center m-1 division">
            <TwitterShareButton
              url={'버츄얼 라이버 모집 오디션 진행중!\n#리스텔라 #버추얼라이버모집\nhttps://listella.tv/'}
            >
              <div className="twitter w-[3vh] h-[8vh] p-0" />
            </TwitterShareButton>
          </ul>
          <ul className="flex justify-center items-center m-1">
            <CopyToClipboard text={'버츄얼 라이버 모집 오디션 진행중!\nhttps://listella.tv/'} onCopy={notify}>
              <button>
                <div className="link w-[3vh] h-[8vh] p-0" />
              </button>
            </CopyToClipboard>
          </ul>
        </div>

        {/* Sections */}
        <div className="z-0 justify-center flex flex-col text-center text-neutral-content p-0 w-screen max-w-full transitionFastest">
          {/* Section0 */}
          <Section id="0" onFocus={(id) => setNav(id)} src="./bg-section01.webp">
            {/* Audition Button */}
            {/* <div className="fill absolute flex flex-col z-20 justify-center scale-100 bottom-24 sm:bottom-8 md:bottom-24 right-1/2 translate-x-1/2 sm:right-12 lg:right-16 xl:right-24 sm:translate-x-0 fadeIn fast">
              <a
                href="https://forms.gle/3no71aQN73VdtAgx7"
                target="_blank"
                rel="noreferrer"
                className="flex flex-row justify-between entryBtn px-[6vh] w-[34vh] h-[10vh]"
              >
                <span>오디션 지원</span>
                <div className="external w-[4vh] h-[4vh]" />
              </a>
            </div> */}
            {!popup && (
              <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 scale-100 cursor-pointer z-30">
                <div onClick={() => setPopup(true)}>
                  <Animate variants={fadeBtn}>
                    <img className="playBtn" src="./btn-play.svg" alt="play button" />
                  </Animate>
                </div>
              </div>
            )}
            <video autoPlay muted loop playsInline className="object-cover w-screen h-screen hidden" id="bgVideo">
              <source src="./bg-video.mp4" type="video/mp4" />
            </video>
            <div className="absolute left-1/2 bottom-4 -translate-x-1/2">
              <img
                className="z-30 animate-bounce fill-black"
                src="./scroll-down.svg"
                alt="stop button"
                style={{ width: '8vh' }}
              />
            </div>
          </Section>
          {/* Section 1 */}
          {/* <ScheduleSection id="1" onFocus={(id) => setNav(id)} src="./bg-section02.webp" w={mWidth} h={mHeight} /> */}
          {/* Section 2 */}
          <NewsSection w={mWidth} h={mHeight} setNav={setNav} />

          {/* Section3 */}
          <Section id="3" onFocus={(id) => setNav(id)} src="./bg-section02.webp">
            <div className="hero-overlay bg-opacity-50 absolute none z-20">
              <div className="section-content">
                <Animate variants={fadeText} delay={300}>
                  <h3 className={`${mWidth < mHeight ? 'my-[6vh]' : 'my-[8vh]'}`}>세계관</h3>
                </Animate>
                <Animate variants={fadeText} delay={600}>
                  <div className="w-[55vh] -space-y-1" style={{ fontSize: '1.7vh', lineHeight: '3.8vh' }}>
                    <p>다양한 지식과 다른 차원의 이야기들을 책의 형태로 저장하는</p>
                    <p>도서관 「루체테」의 수많은 열람실 중 한 곳.</p>
                    <br />
                    <p>나 홀로 도서관의 이야기를 알리느라 고군분투하고 있는 사서에게</p>
                    <p>갑작스러운 「스텔라」의 출현으로 한 줄기 빛이 보이기 시작한다.</p>
                    <br />
                    <p>각자의 강한 염원과 꿈을 매개체로 삼아</p>
                    <p>책 속에서 도서관으로 넘어오게 된 「스텔라」</p>
                    <br />
                    <p>원하는 것을 이루기 위해 「스텔라」는 사서에게 도움을 청하지만,</p>
                    <p>도서관의 업무에 진절머리가 난 사서는</p>
                    <p>지식을 대가로 「스텔라」에게 도서관의 홍보를 요구한다.</p>
                    <br />
                    <p>사람들의 기억에서 잊힐수록 사라지는 도서관 「루체테」에서</p>
                    <p>얼떨결에 홍보대사 임무를 맡게 된 「스텔라」들의 이야기</p>
                  </div>
                </Animate>
                <div className="absolute left-1/2 bottom-4 -translate-x-1/2">
                  <img
                    className="z-30 animate-bounce fill-black"
                    src="./scroll-down.svg"
                    alt="stop button"
                    style={{ width: '8vh' }}
                  />
                </div>
              </div>
            </div>
          </Section>

          {/* Section4 */}
          <Section id="4" onFocus={(id) => setNav(id)} src="./bg-section03.webp">
            <CharacterSection w={mWidth} h={mHeight} />
          </Section>

          {/* Section5 */}
          {/* 로컬 환경에서 과부하가 심하게 걸림 */}
          <MediaSection w={mWidth} h={mHeight} setNav={setNav} />
        </div>
      </div>
    </div>
  );
}

export default Home;
