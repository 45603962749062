const useCharactor = () => {
  const charactor = {
    YATO: {
      colors: {
        name: '#ff9e1f',
        nameBorder: '#fffccb',
      },
      imageURL: '/RT/Yato_RT_20-1.png',
      platformLink: 'https://chzzk.naver.com/e87999abca4fd0c3214e05ef414ce951',
    },
    ODANMING: {
      colors: {
        name: '#ff9e1f',
        nameBorder: '#fffccb',
      },
      imageURL: '/RT/Danming_RT_20.png',
      platformLink: 'https://chzzk.naver.com/f3b204dd3fd6925835ca1848cd4b6d3c'
    },
    HAMKUBBY: {
      colors: {
        name: '#ff9e1f',
        nameBorder: '#fffccb',
      },
      imageURL: '/RT/Kubby_RT_20.png',
      platformLink: 'https://chzzk.naver.com/9351fb8417f73405c84e0846409e3263'
    },
    LOBOMOONLIT: {
      colors: {
        name: '#ff9e1f',
        nameBorder: '#fffccb',
      },
      imageURL: '/RT/Lobo_RT_20.png',
      platformLink: 'https://chzzk.naver.com/95cd5ac9404064f5c56eeba691931003'
    },
  };

  return { charactor };
};

export default useCharactor;
